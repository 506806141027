import ReactTypingEffect from 'react-typing-effect';
import image from '../images/hama.png';
import aboutme from '../images/about_me.jpg';
import Card from './Card';
export default function Home({ page, changePage }) {
    const goToContact = (e) => {
        changePage("Contact");
    }
    const runCallback = (cb) => {
        return cb();
    };
    const displayServices = () => {
        return (
            runCallback(() => {
                const row = [];
                for (var i = 0; i < 7; i++) {
                    row.push(<Card index={i} />);
                }
                return row;
            })

        );
    }
    return (
        <>
            <section id="home">
                <div className="grid grid-cols-2 gap-2 transition-all">
                    <div>
                        <h3 className="font-extrabold leading-6 text-2xl font-Catamaran text-cooler mt-24">Hey, I'm Mohamed Derbali</h3>
                        <p className="mt-10 text-7xl font-bold">
                            I love to code since it makes a difference in peoples’ lives.
                        </p>
                        <img src="https://assets.website-files.com/61129bb2ecedf803564c68ec/6112b0068db0b60858060db4_hero-underline-personal-x-template.svg" />
                        <button className="text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br font-Catamaran font-extrabold focus:ring-4 focus:ring-gray-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 rounded-full text-lg px-5 py-2.5 text-center mr-2 mb-2 w-56 h-16 mt-3 ml-3 animate-bounce hover:bg-white hover:text-black" onClick={(e) => goToContact()}>Get in touch</button>

                        {/*<ReactTypingEffec text={["SOFTWARE ENGINEER", "WEB DEVELOPER"]} />*/}
                    </div>
                    <div className="pl-96">
                        <img src={image} className="w-96"></img>
                    </div>
                </div>
            </section>
            <section className="px-52" id="services">
                <div className="mt-48 font-Catamaran">
                    <p className=" text-5xl font-bold text-center mb-6">
                        My Services
                    </p>
                    <p className=" text-xl text-center mb-6 px-10 text-gray-500 font-medium">
                        I m always striving to make quality work, and my goal as an engineer is to build strong, long term , mutually, beneficial relationships with colleagues and clients alike Where i offer the services below
                    </p>


                    <div className=" grid grid-cols-2 h-auto bg-purple-100 rounded-3xl opacity-50">
                        {displayServices()}
                    </div>
                </div>
            </section>
            <section id="aboutme">
                <div className="mt-48 font-Catamaran grid grid-cols-2">
                    <div className="p-4">
                        <img src={aboutme} className="w-96 rounded-3xl h-96"></img>
                    </div>
                    <div>
                        <p className=" text-5xl font-bold mb-6 mt-10">
                            About me
                        </p>
                        <p className=" text-xl mb-6 text-gray-500 font-medium">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus mattis nunc aliquam tincidunt est non. Viverra nec eu.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}