import logo from '../images/mail.svg'
import React, { useState } from 'react';
import logophone from '../images/phone.svg'
const defaults = [
    true, true, true, true, true
]
export default function Contact() {
    const [questions, setQuestions] = useState(defaults)
    const changeQuestion = (e, index) => {
        let arr = [...questions];
        arr[index] = !arr[index];
        setQuestions(arr)
    }
    return (
        <div className="content-center px-20">
            <p className=" text-7xl font-bold text-center">
                Contact me
            </p>
            <p className="text-center text-gray-500 font-semibold mt-12 font-Catamaran">
                While i'm good with smoke signals, there are simpler ways for me to get in touch.
            </p>
            <div className="px-40">
                <div className="border-2 rounded-3xl px-9 py-14 font-bold font-Catamaran mt-12">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="...">
                            <h1 className="text-xl">Name</h1>
                            <input type="text" className="border-2  rounded-full p-5 mt-4 w-full text-lg font-semibold" placeholder="Full Name" />
                        </div>
                        <div className="...">
                            <h1 className="text-xl">Email Address</h1>
                            <input type="text" className="border-2  rounded-full p-5 mt-4 w-full text-lg font-semibold" placeholder="contact@gmail.com" />
                        </div>
                        <div className="mt-2">
                            <h1 className="text-xl">Phone</h1>
                            <input type="text" className="border-2  rounded-full p-5 mt-4 w-full text-lg font-semibold" placeholder="(415) 200 - 8613" />
                        </div>
                        <div className="mt-2">
                            <h1 className="text-xl">Subject</h1>
                            <input type="text" className="border-2  rounded-full p-5 mt-4 w-full text-lg font-semibold" placeholder="Ex. New Project" />
                        </div>
                        <div className="col-span-2">
                            <h1 className="text-xl">Message</h1>
                            <textarea type="text" className="border-2  rounded-xl p-5 mt-4 w-full text-lg font-semibold" placeholder="Please write your message..." />
                        </div>
                        <div className="mt-2">
                            <button type="button" className="text-white bg-gray-800  focus:ring-4 focus:ring-gray-300 font-extrabold rounded-full  mr-2 mb-2 w-56 h-16 mt-3 ml-3  text-lg px-5 py-2.5 text-center hover:bg-white hover:text-black dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700 shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">Send Message</button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 p-10 text-lg font-semibold text-gray-500">
                <div className="mt-2 px-44">
                    <a href="mailto:mohamed.derbali@esprit.tn" className="hover:text-gray-500 ">
                        <div className="flex items-center">
                            <img src={logo} className="w-25 h-4 mr-2" />
                            <span>mohamed.derbali@esprit.tn</span>
                        </div>
                    </a>
                </div>
                <div className="mt-2 px-8">
                    <a href="tel:21626976371" className="hover:text-gray-500 ">
                        <div className="flex items-center">
                            <img src={logophone} className="w-25 h-4 mr-2" />
                            <span>(216) 26 976 371</span>
                        </div>
                    </a>
                </div>
            </div>
            <hr className="font-extrabold" />
            <p className=" text-6xl font-bold text-center mt-24 mb-12">
                Frequently Asked Questions
            </p>
            <div className="px-36">
            <div className="mb-12">
            <div className="flex items-center text-2xl text-cooler font-bold font-Catamaran ">
               
                {questions && questions[0] === true ?
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 0)}>+</button> :
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 0)}>-</button>
                }
                <span>Are you open for freelance / contract work?</span>
                
                
            </div>
            <div className="px-20" hidden={questions[0]}>
                <p className="ml-2 text-gray-400 font-medium">I am Delighted to build new applications, i would love the opportunity to talk with you about the position and what I might bring to it.</p>
            </div>
            </div>
            <hr/>
            <div className="mb-12 mt-8">
            <div className="flex items-center text-2xl text-cooler font-bold font-Catamaran ">
               
                {questions && questions[1] === true ?
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 1)}>+</button> :
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 1)}>-</button>
                }
                <span>Are you open for project collaborations?</span>
                
                
            </div>
            <div className="px-20" hidden={questions[1]}>
                <p className="ml-2 text-gray-400 font-medium">I always enjoy working in team, i'm looking forward to chatting more about this.😀</p>
            </div>
            </div>
            <hr/>
            <div className="mb-12 mt-8">
            <div className="flex items-center text-2xl text-cooler font-bold font-Catamaran ">
               
                {questions && questions[2] === true ?
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 2)}>+</button> :
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 2)}>-</button>
                }
                <span>Are you open for guest-posts on your blog?</span>
                
                
            </div>
            <div className="px-20" hidden={questions[2]}>
                <p className="ml-2 text-gray-400 font-medium">Yes, I do. Guest post is a valuable marketing tactic that can boost my traffic website. By accepting the guest post, my blog will get good content that can attract the audience, and it can make our website high rank in Google.</p>
            </div>
            </div>
            <hr/>
            <div className="mb-12 mt-8">
            <div className="flex items-center text-2xl text-cooler font-bold font-Catamaran ">
               
                {questions && questions[3] === true ?
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 3)}>+</button> :
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 3)}>-</button>
                }
                <span>Are you currently looking for fulltime roles?</span>
                
                
            </div>
            <div className="px-20" hidden={questions[3]}>
                <p className="ml-2 text-gray-400 font-medium">I would love the opportunity to become a full-time employee. I am very excited at the prospect of this part-time job, but I would gladly accept a full-time position if it were ever available. I believe my organizational and time management skills would make me a very strong full-time employee.</p>
            </div>
            </div>
            <hr/>
            <div className="mb-12 mt-8">
            <div className="flex items-center text-2xl text-cooler font-bold font-Catamaran ">
               
                {questions && questions[4] === true ?
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 4)}>+</button> :
                    <button className="px-4 py-1 rounded-xl mr-12 bg-gray-200 text-gray-500 font-bold font-Catamaran hover:bg-black hover:text-white" onClick={(e) => changeQuestion(e, 4)}>-</button>
                }
                <span>What's your past experience on web developement?</span>
                
                
            </div>
            <div className="px-20" hidden={questions[4]}>
                <p className="ml-2 text-gray-400 font-medium">I've been working as web developer since 2018, i started with PHP and Symfony where i build many e-commerce applications that sells (perfume, candy and clothes)
                then i moved to learn Javascript that i've become addicted to it i learned NodeJs, ReactJs and NextJs and web design technologies (css, tailwindcss and bootstrap) after that i build 2 Backend application:
                the first is for a candian association named Forbetter where i volunteered to develop a backend application with expressJs and NodeJs, Second i worked as freelancer to create a backend application for
                a frensh company named the team that manages different web and mobile applications and last i've build my Blog using React, tailwindcss.</p>
            </div>
            </div>
            </div>
            
        </div>

    )
}