import logo from '../images/mail.svg'
import React, { useState } from 'react';
import logophone from '../images/phone.svg'
export default function Header() {
  return (
    <>

      <footer className="border-t border-gray-200 font-Catamara relative w-full bottom-0 bg-white mt-80">

        <div
          className="
            container
            flex flex-col flex-wrap
            px-4
            py-16
            w-full md:max-w-7xl mx-auto
            md:items-center
            lg:items-start
            md:flex-row md:flex-nowrap
          "
        >
          <div
            className="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left"
          >
            <a
              className="
                flex
                items-center
                justify-center
                text-4xl
                font-bold
                text-blue-700
                md:justify-start
              "
              href="/"
            >
              <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-code-slash" viewBox="0 0 16 16" className="font-indie text-black font-extrabold mt-25">
                  <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z" />
                </svg>&nbsp;<h3 className="font-bold font-indie text-black text-3xl mt-1">Mohamed Dev</h3>
              </div>
            </a>
            <p className="mt-2 text-sm text-gray-500">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
              at sequi cum, impedit fuga in placeat illo eum minima possimus est
              perferendis distinctio explicabo eos natus consequuntur blanditiis
              odio optio?
            </p>
          </div>
          <div className="justify-between w-full mt-4 text-center lg:flex">
            <div className="w-full px-4 lg:w-1/3 md:w-1/2">
              <h2 className="mb-2 font-bold tracking-widest text-gray-900">
                Pages
              </h2>
              <ul className="mb-8 space-y-2 text-sm list-none">
                <li>
                  <a className="text-gray-600 hover:text-gray-800">Home</a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">About Us</a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">Blogs</a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="w-full px-4 lg:w-1/3 md:w-1/2">
              <h2 className="mb-2 font-bold tracking-widest text-gray-900">
                Utilities
              </h2>
              <ul className="mb-8 space-y-2 text-sm list-none">
                <li>
                  <a className="text-gray-600 hover:text-gray-800">Home</a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">About Us</a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">Blogs</a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="w-full px-4 lg:w-1/3 md:w-1/2">
              <h2 className="mb-2 font-bold tracking-widest text-gray-900">
                Contact Me
              </h2>
              <ul className="mb-8 space-y-2 text-sm list-none">
                <li className="ml-14">
                  <a href="mailto:mohamed.derbali@esprit.tn" className="hover:text-gray-500 ">
                    <div className="flex items-end">
                      <img src={logo} className="w-25 h-4 mr-2" />
                      <span>mohamed.derbali@esprit.tn</span>
                    </div>
                  </a>
                </li>
                <li className="ml-14"><a href="tel:21626976371" className="hover:text-gray-500 ">
                  <div className="flex items-center">
                    <img src={logophone} className="w-25 h-4 mr-2" />
                    <span>(216) 26 976 371</span>
                  </div>
                </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </footer>

    </>
  )
}