import ChatBot from 'react-simple-chatbot';
import chat from '../images/chat.svg'
import facebook from '../images/contacts/facebook.svg'
import gmail from '../images/contacts/gmail.svg'
import linkedin from '../images/contacts/linkedin.svg'
import whatsapp from '../images/contacts/whatsapp.svg'
import skype from '../images/contacts/skype.svg'
export default function ChatBotBox({ show, changeVisibility }) {
    const openChatBox = (e) => {
        changeVisibility(!show);
    }
    return (
        <div className="absolute right-5">
            {!show && <div className="transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none w-28 h-0"><a className="cursor-pointer " onClick={(e) => openChatBox(e)}>
                <h3 className="font-bold font-indie text-black text-2xl "> Let's chat!</h3>
                <img src={chat} className="w-16 mr-2 " />
            </a>
            </div>}

            {(show) && <div className="grid-cols-2">
                <ChatBot className="-mt-28 mb-3" handleEnd={openChatBox}
                    steps={[
                        {
                            id: '1',
                            message: 'Greeting, how may i help you? Feel free to choose what you want to know 😀.',
                            trigger: '3',
                        },
                        {
                            id: '2',
                            message: 'Do you need more informations?',
                            trigger: '3',
                        },
                        {
                            id: '3',
                            options: [
                                { value: 1, label: 'About me', trigger: '5' },
                                { value: 2, label: 'My work', trigger: '6' },
                                { value: 3, label: 'Contact me', trigger: '7' },
                                { value: 4, label: 'End conversation', trigger: '11' },
                            ],
                        },
                        {
                            id: '5',
                            message: 'I am mohamed derbali 25 years old software engineer',
                            trigger: '3',
                        },
                        {
                            id: '9',
                            message: 'i will respond to you as soon as possible!',
                            trigger: '3',
                        },
                        {
                            id: '6',
                            message: 'I am working as fullstack Javascript developer \n with strong knowledge of building backend(NodeJs) and frontend web applications(ReactJs, NextJs) \n i am also quite good with web design (html, css, tailwind css, bootstrap) and deployement (Amazon web service, versel, netlify) and familiar with other technologies and languages java, php, spring, symfony, laravel, c++, c.',
                            trigger: '3',
                        },
                        {
                            id: '7',
                            component: (
                                <div> You can contact me via: <br />
                                    <div className="px-50">
                                        <div className="inline"><a href="https://www.linkedin.com/in/mohamed-derbali/" target="_blank" className="flex items-center"><img src={linkedin} className="w-8" /><span className="float-right">&nbsp;LinkedIn</span></a></div><br />
                                        <div className="inline"> <a href="mailto:mohamed.derbali@esprit.tn" target="_blank" className="flex items-center"><img src={gmail} className="w-8 float-left" /><span className="float-right">&nbsp;Gmail</span></a> </div><br />
                                        <div className="inline"><a href="skype:live:.cid.7ba2b7461fccedab?userinfo" target="_blank" className="flex items-center"><img src={skype} className="w-8 float-left" /><span className="float-right">&nbsp;Skype</span></a> </div><br />
                                        <div className="inline"> <a href="https://wa.me/216976371" className="flex items-center" target="_blank"><img src={whatsapp} className="w-8 float-left" /><span className="float-right">&nbsp;Whatsapp</span></a></div> <br />
                                        <div className="inline"><a href="https://www.facebook.com/mohamed.derbali.mohamed/" target="_blank" className="flex items-center"><img src={facebook} className="w-8 float-left" /><span className="float-right">&nbsp;Facebook</span></a></div>
                                    </div>

                                </div>
                            ),
                            asMessage: true,
                            trigger: '9',
                        },
                        {
                            id: '11',
                            message: 'GoodBye! have a great evening',
                            trigger: '12'
                        },
                        {
                            id: '12',
                            message: ' ',
                            end: true,
                        },
                    ]}
                />

            </div>}
        </div>
    )
}