import importAll from '../helpers/MultipleImports'
const imagesWebPack = importAll(require.context('../images/services', false, /\.(svg)$/));
const elements = [
    { title: 'Web developement', description: 'javascript, NodeJs, ReactJs, Php, Laravel, Symfony, Html, Css, Tailwind Css, SpringBoot, JavaEE, JSF, Angular', image: 'web.svg' },
    { title: 'Web Scrapping', description: 'BeautifulSoup 4, selinium', image: 'data-collection.svg' },
    { title: 'Databases Managemenet', description: 'Mysql, MongoDB, PostgreSQL', image: 'database-system.svg' },
    { title: 'Mobile developement', description: 'React native, Android, Javascript, Java', image: 'mobile-weather-application.svg' },
    { title: 'Desktop developement', description: 'Qt, C++, Java, JavaFx, SDL, C', image: 'desktop-banking.svg' },
    { title: 'Deployment', description: 'Amazon Web Service, Heroku, Vercel, Netlify', image: 'server.svg' },
    { title: 'Server ressources Managemenet', description: 'Ngnix, Docker, Dns, Https', image: 'server-resource-scaling.svg' },
]
export default function Card({ index }) {
    const photo = elements[index]?.image
    const importedImage = imagesWebPack[`${photo}`].default
    return (
        <>{(index % 2 === 0) ?
            <div className="max-w-md py-8 px-8 bg-white shadow-lg rounded-lg my-10 -ml-28 grid grid-cols-3">
                <div className="pr-2">
                <img src={importedImage}></img>
                </div>
                <div className="col-span-2">
                    <h2 className="text-gray-800 text-2xl font-semibold">{elements[index]?.title}</h2>
                    <p className="mt-2 text-gray-600">{elements[index]?.description}</p>
                </div>
            </div> : <div className="max-w-md py-8 px-8 bg-white shadow-lg rounded-lg my-10 -mr-28 grid grid-cols-3">
                <div className="pr-2">
                <img src={importedImage} ></img>
                </div>
                <div className="col-span-2">
                    <h2 className="text-gray-800 text-2xl font-semibold">{elements[index]?.title}</h2>
                    <p className="mt-2 text-gray-600">{elements[index]?.description}</p>
                </div>
            </div>
        }

        </>
    )
}