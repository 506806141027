import logo from '../images/mail.svg'
export default function Header({ page, changePage }) {
    return (

        <nav id="header" className="fixed w-full z-10 top-0 bg-white">

            <div id="progress" className="h-1 z-20 top-0"></div>
        
            <div className="w-full md:max-w-7xl mx-auto flex flex-wrap items-center justify-between mt-4 py-3">

                <div className="pl-4 flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-code-slash" viewBox="0 0 16 16" className="font-indie text-black font-extrabold mt-25">
                        <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z" />
                    </svg>&nbsp;&nbsp;<h3 className="font-bold font-indie text-black text-3xl mt-1">Mohamed Dev</h3>
                </div>

                <div className="block lg:hidden pr-4">
                    <button id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-green-500 appearance-none focus:outline-none">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>

                <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-gray-100 md:bg-transparent z-20" id="nav-content">
                    <ul className="list-reset lg:flex justify-end flex-1 items-center">
                        <li className={page == 'Home' ? "text-gray-500 underline" : "text-black hover:text-gray-400 hover:underline "}>
                            <a className="block py-2 pr-4 pl-3 no-underline" href="#home" onClick={() => changePage('Home')}>Home</a>
                        </li>
                        <li className={page == 'About' ? "text-gray-500 underline " : "text-black hover:text-gray-400 hover:underline "}>
                            <a className="block py-2 pr-4 pl-3" onClick={() => changePage('About')}>About</a>
                        </li>
                        <li className={page == 'Services' ? "text-gray-500 underline " : "text-black hover:text-gray-400 hover:underline "}>
                            <a className="block py-2 pr-4 pl-3" href="#services">Services</a>
                        </li>
                        <li>
                            <a href="mailto:mohamed.derbali@esprit.tn" className="hover:text-gray-500 ">
                                <div className="flex inset-y-0 left-1 items-center pl-2">
                                  <img src={logo} className="w-25 h-4"/>&nbsp;
                                   <h1 className="text-lg hover:underline">mohamed.derbali@esprit.tn</h1>
                                </div>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>

    )
}