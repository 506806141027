import React, { useState } from 'react';
import './App.css';
import './styles/output.css';
import Header from './Header/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import ChatBotBox from './pages/ChatBot';
function App() {
  const [page, setPage] = useState('Home')
  const [showChatBot, setShowChatBot] = useState(false)
  return (<>
    <Header page={page} changePage={setPage} />
    <div className="container w-full md:max-w-7xl mx-auto pt-20 mb-48">
      <div className="mt-24 px-6">
        {page == 'Home' ? <Home page={page} changePage={setPage} /> : page == 'About' ? <About /> : page == 'Contact' ? <Contact /> : <About />}
      </div>
    </div>
    <ChatBotBox show={showChatBot} changeVisibility={setShowChatBot} />
    <Footer />
  </>);
}

export default App;
